import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_event_product_ships',
  attributes: [
    'id',
    'promoter_event_id',
    'campaign_id',
    'product_id',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterEventProductShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
}
