export const FETCH_USER_COMMON_ADDRESSES_SUCCESS =
  'FETCH_USER_COMMON_ADDRESSES_SUCCESS'
export const GET_RELATED_USER_COMMON_ADDRESSES_SUCCESS =
  'GET_RELATED_USER_COMMON_ADDRESSES_SUCCESS'
export const GET_USER_COMMON_ADDRESS_SUCCESS = 'GET_USER_COMMON_ADDRESS_SUCCESS'
export const ADD_USER_COMMON_ADDRESS_SUCCESS = 'ADD_USER_COMMON_ADDRESS_SUCCESS'
export const UPDATE_USER_COMMON_ADDRESS_SUCCESS =
  'UPDATE_USER_COMMON_ADDRESS_SUCCESS'
export const DELETE_USER_COMMON_ADDRESS_SUCCESS =
  'DELETE_USER_COMMON_ADDRESS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
