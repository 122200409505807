export const FETCH_USER_COLLECTIONS_SUCCESS = 'FETCH_USER_COLLECTIONS_SUCCESS'
export const GET_RELATED_USER_COLLECTIONS_SUCCESS =
  'GET_RELATED_USER_COLLECTIONS_SUCCESS'
export const GET_USER_COLLECTIONS_SUCCESS = 'GET_USER_COLLECTIONS_SUCCESS'
export const ADD_USER_COLLECTIONS_SUCCESS = 'ADD_USER_COLLECTIONS_SUCCESS'
export const UPDATE_USER_COLLECTIONS_SUCCESS = 'UPDATE_USER_COLLECTIONS_SUCCESS'
export const DELETE_USER_COLLECTIONS_SUCCESS = 'DELETE_USER_COLLECTIONS_SUCCESS'
export const STORE_USER_COLLECTIONS_FROM_LOCAL =
  'STORE_USER_COLLECTIONS_FROM_LOCAL'
export const SET_LATEST_UPDATED_AT_FROM_LOCAL =
  'SET_LATEST_UPDATED_AT_FROM_LOCAL'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
