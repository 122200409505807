export const flashMessages = (state) => {
  return state.flashMessages
}

/**
 * get currentUser state
 *
 * @returns {Object} current user object
 */
export const currentUser = (state) => {
  return state.currentUser
}

export const queryString = (state) => {
  return state.queryString
}

export const isMobile = (state) => {
  return state.device.isMobile && state.device.deviceType !== 'tablet'
}

export const windowWidth = (state) => {
  return state.device.windowWidth
}

export const mobileMenuStates = (state) => {
  return state.mobileMenuStates
}
