import UserCollection from '../../resource_models/user_collections'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new UserCollection(state.entities[id]))
}

export const currentCollections = (state) => {
  if (state.currentCollections.length > 0) {
    return state.currentCollections
  } else {
    return state.result
      .map((id) => state.entities[id])
      .map((collection) => String(collection.product_id))
  }
}

export const latestUpdatedAt = (state) => {
  return state.meta.latest_updated_at
}

export const find = (state) => (id) => {
  return new UserCollection(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
