export const FETCH_USER_CREDIT_CARDS_SUCCESS = 'FETCH_USER_CREDIT_CARDS_SUCCESS'
export const GET_RELATED_USER_CREDIT_CARDS_SUCCESS =
  'GET_RELATED_USER_CREDIT_CARDS_SUCCESS'
export const GET_USER_CREDIT_CARD_SUCCESS = 'GET_USER_CREDIT_CARD_SUCCESS'
export const ADD_USER_CREDIT_CARD_SUCCESS = 'ADD_USER_CREDIT_CARD_SUCCESS'
export const UPDATE_USER_CREDIT_CARD_SUCCESS = 'UPDATE_USER_CREDIT_CARD_SUCCESS'
export const DELETE_USER_CREDIT_CARD_SUCCESS = 'DELETE_USER_CREDIT_CARD_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
