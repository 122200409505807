import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_event_variant_ships',
  attributes: ['id', 'price', 'event_product_ship_id', 'variant_id', 'created_at', 'updated_at'],
  editableAttributes: ['price', 'variant_id'],
}

export default class PromoterEventVariantShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
